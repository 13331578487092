<template>
    <v-row justify="center">
      <v-dialog
        v-model="shower"
        scrollable
        persistent
        max-width="600px"
        v-if="resolutionScreen >= 500"
      >
        <v-card v-if="resolutionScreen >= 400">
          <v-card-title>
            <v-layout row wrap>
            <v-flex lg11 sm11 xs10 class="pl-2">
              <v-list-item-title style="font-size: 18px;"
                >{{
                  filedata.file_type === "folder"
                    ? $t("movefiles.movefolder")
                    : $t("movefiles.movefiles")
                }}
                {{ filedata.file_name }}</v-list-item-title
              >
            </v-flex>
            <v-flex lg1 sm1 xs2>
              <v-icon
                class="text-right"
                :color="color.theme"
                dense
                dark
                size="35"
                >mdi-folder-swap</v-icon
              >
            </v-flex>
          </v-layout>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 400px">
            <v-tabs v-model="tab">
              <v-tab
              @click="
                (checktab = 1)
              "
              >{{ $t("toolbar.mydrive") }}</v-tab
            >
            <v-tab
              @click="(checktab = 2)"
              >{{ $t("toolbar.mydrive_bu") }}</v-tab
            >
            <v-tabs-items v-model="tab">
            <v-tab-item> 
            <v-layout row wrap class="pa-4">
              <v-flex v-if="createprogress === true && createlodingprogress === false">
                <v-layout justify-center>
                  <br />
                  <v-progress-circular
                    class="mt-6 mb-6 pt-6 pb-6"
                    :size="50"
                    :dark="color.darkTheme"
                    :color="color.theme"
                    indeterminate
                  ></v-progress-circular>
                </v-layout>
              </v-flex>
              <v-flex v-else-if="createprogress === false && createlodingprogress === false">
                <v-list nav dense v-if="listdirectory.length > 0">
                  <!-- <v-subheader > -->
                  <v-layout row wrap>
                    <v-flex lg12 sm12 xs10 class="mt-1">
                      <v-chip :color="color.theme" text-color="white">
                        <v-btn
                            :disabled="enableback"
                            @click="querydirectories_v2(parentdirectory)"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                        <span class="ml-4" v-if="resolutionScreen >= 400">
                          {{ parentname }}</span
                        >
                        <span class="ml-4" v-else>{{
                          parentname.length >= 30
                            ? parentname.substring(0, 30) + "..."
                            : parentname
                        }}</span>
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <br />
                  <!-- </v-subheader> -->
                  <!-- <v-list-item-group v-model="item" :color="color.theme" @change="clicker"> -->
                  <v-list-item-group v-model="item" :color="color.theme">
                    <!-- {{listdirectory}} -->
                    <!-- //! ของเดิมใช้ folder_id -->
                    <!-- //? ใช้ @click bind moveto กับ id -->
                    <!-- {{ moveto }} -->
                    <v-list-item
                      v-for="(item, i) in listdirectory"
                      :key="i"
                      @dblclick="query_sub_directories(item.folder_id)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          :color="
                            item.folder_status === 'M' ? 'green' : '#ffc107'
                          "
                          large
                          >folder</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <!-- //! ของเดิมใช้ folder_name -->
                        <v-list-item-title>{{
                          item.folder_name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-list nav dense v-else>
                  <v-layout row wrap>
                    <!-- <v-flex lg1 sm1 xs2>
                    <v-btn
                      :disabled="enableback"
                      @click="querydirectories(parentdirectory)"
                      small
                      fab
                      :color="color.theme"
                    >
                      <v-icon :color="color.chipText">keyboard_backspace</v-icon>
                    </v-btn>
                  </v-flex> -->
                    <v-flex lg12 sm12 xs10 class="mt-1">
                      <!-- <v-subheader class="pl-0"> -->
                      <v-chip :color="color.theme" text-color="white">
                        <v-btn
                            :disabled="enableback"
                            @click="querydirectories_v2(parentdirectory)"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                        <span class="ml-4"> {{ parentname }}</span>
                      </v-chip>
                      <!-- </v-subheader> -->
                    </v-flex>
                  </v-layout>
  
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-center">{{
                        $t("movefiles.nofolder")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex v-if="createlodingprogress === true" class="mt-5 text-center">
                  <v-progress-circular
                  :size="110"
                  :width="10"
                  :value="calpercenrestore"
                  :color="finish_move_success ? 'green' :color.theme"
                  >
                    <span v-if="!finish_move_success">
                      {{ calpercenrestore }}%
                    </span>
                    <v-icon v-else color="green">
                      mdi-check-circle
                    </v-icon>
                  </v-progress-circular>
                  <v-layout justify-center class="pt-2">
                    <span v-if="!finish_move_success">
                      {{$t('multirecovery.movingfilefolder')}}
                    </span>
                    <span v-else>
                      {{$t('multirecovery.movesuccess')}}
                    </span>
                  </v-layout>
              </v-flex>
            </v-layout>
            </v-tab-item>
            <v-tab-item> 
            <v-layout row wrap class="pa-4">
              <v-flex v-if="createprogress === true && createlodingprogress === false">
                <v-layout justify-center>
                  <br />
                  <v-progress-circular
                    class="mt-6 mb-6 pt-6 pb-6"
                    :size="50"
                    :dark="color.darkTheme"
                    :color="color.theme"
                    indeterminate
                  ></v-progress-circular>
                </v-layout>
              </v-flex>
              <v-flex v-else-if="createprogress === false && createlodingprogress === false">
                <v-list nav dense v-if="listdirectory.length > 0">
                  <!-- <v-subheader > -->
                  <v-layout row wrap>
                    <v-flex lg12 sm12 xs10 class="mt-1">
                      <v-chip :color="color.theme" text-color="white">
                        <v-btn
                            :disabled="enableback"
                            @click="querydirectories_v2(parentdirectory)"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                        <span class="ml-4" v-if="resolutionScreen >= 400">
                          {{ parentname }}</span
                        >
                        <span class="ml-4" v-else>{{
                          parentname.length >= 30
                            ? parentname.substring(0, 30) + "..."
                            : parentname
                        }}</span>
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <br />
                  <!-- </v-subheader> -->
                  <!-- <v-list-item-group v-model="item" :color="color.theme" @change="clicker"> -->
                  <v-list-item-group v-model="item" :color="color.theme">
                    <!-- {{listdirectory}} -->
                    <!-- //! ของเดิมใช้ folder_id -->
                    <!-- //? ใช้ @click bind moveto กับ id -->
                    <!-- {{ moveto }} -->
                    <v-list-item
                      v-for="(item, i) in listdirectory"
                      :key="i"
                      @dblclick="query_sub_directories(item.folder_id)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          :color="
                            item.folder_status === 'M' ? 'green' : '#ffc107'
                          "
                          large
                          >folder</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <!-- //! ของเดิมใช้ folder_name -->
                        <v-list-item-title>{{
                          item.folder_name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-list nav dense v-else>
                  <v-layout row wrap>
                    <v-flex lg12 sm12 xs10 class="mt-1">
                      <!-- <v-subheader class="pl-0"> -->
                      <v-chip :color="color.theme" text-color="white">
                      <v-btn
                            :disabled="enableback"
                            @click="querydirectories_v2(parentdirectory)"
                            style="cursor: pointer; background-color: transparent !important;"
                            icon
                            color="white"
                            class="ml-n1 mr-n2"
                          >
                            <v-icon>mdi-arrow-left-circle</v-icon>
                          </v-btn>
                        <span class="ml-4"> {{ parentname }}</span>
                      </v-chip>
                      <!-- </v-subheader> -->
                    </v-flex>
                  </v-layout>
  
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-center">{{
                        $t("movefiles.nofolder")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex v-if="createlodingprogress === true" class="mt-5 text-center">
                  <v-progress-circular
                  :size="110"
                  :width="10"
                  :value="calpercenrestore"
                  :color="finish_move_success ? 'green' :color.theme"
                  >
                    <span v-if="!finish_move_success">
                      {{ calpercenrestore }}%
                    </span>
                    <v-icon v-else color="green">
                      mdi-check-circle
                    </v-icon>
                  </v-progress-circular>
                  <v-layout justify-center class="pt-2">
                    <span v-if="!finish_move_success">
                      {{$t('multirecovery.movingfilefolder')}}
                    </span>
                    <span v-else>
                      {{$t('multirecovery.movesuccess')}}
                    </span>
                  </v-layout>
              </v-flex>
            </v-layout>
            </v-tab-item>
            </v-tabs-items>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              outlined
              @click="$emit('closedlmovefile'), cleardata(), $emit('openDrag')"
              :disabled="createprogress"
              >{{ $t("movefiles.buttonclose") }}</v-btn
            >
            <v-btn
              :color="color.theme"
              :dark="color.darkTheme"
              @click="check_summitmove(), $emit('openDrag')"
              :loading="createprogress"
            >
              {{
                this.item === "" || this.item === undefined ? $t("movefiles.maveat") : $t("movefiles.move")
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
  
        <!-- ขนาดหน้าจอโทรศัพท์ -->
      </v-dialog>
      <!-- หน้าจอมือถือ -->
      <v-dialog
        v-model="shower"
        scrollable
        persistent
        :max-width="maxWidthOnMobile"
        content-class="rounded-lg"
        v-else
      >
        <v-card>
        <v-tabs v-model="tab" outlined> 
            <v-tab
            @click="(checktab = 1)"
            >{{ $t("toolbar.mydrive") }}</v-tab
          >
          <v-tab
            @click="(checktab = 2)"
            >{{ $t("toolbar.mydrive_bu") }}</v-tab
          >
         <v-tab-item>
          <v-card-text class="pa-5">
              <v-layout class="pt-2 pb-4">
                <v-flex d-flex justify-center class="ml-8">
                  <span :style="headerPage">{{
                    filedata.file_type === "folder"
                      ? $t("movefiles.movefolder")
                      : $t("movefiles.movefiles")
                  }}</span>
                </v-flex>
                <v-flex xs1 d-flex justify-end>
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    @click="
                      $emit('closedlmovefile'), cleardata(), $emit('openDrag')
                    "
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-chip
                v-if="createprogress === false"
                :style="headerChipPage"
                :color="$vuetify.theme.dark ? '#989898' : color.theme"
              >
              <v-btn
                  :disabled="enableback"
                  @click="querydirectories_v2(parentdirectory)"
                  style="cursor: pointer; background-color: transparent !important;"
                  icon
                  color="white"
                  class="ml-n1 mr-3"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'white'"
                    dark
                    size="25"
                    >mdi-arrow-left-circle</v-icon
                  >
                </v-btn>
                <v-list-item-title class="ml-n2">
                  <span
                    :style="headerPageMobile"
                    v-if="resolutionScreen >= 400"
                  >
                    {{ parentname }}</span
                  >
                  <span :style="headerPageMobile" v-else>{{
                    parentname.length >= 30
                      ? parentname.substring(0, 30) + "..."
                      : parentname
                  }}</span>
                </v-list-item-title>
              </v-chip>
          </v-card-text>
          <v-card-text style="overflow-y: scroll; height: 400px;">
              <v-layout row wrap>
                <v-flex v-if="createprogress === false">
                  <v-list nav dense v-if="listdirectory.length > 0">
                    <v-list-item-group v-model="item" :color="color.theme">
                      <v-list-item
                        v-for="(item, i) in listdirectory"
                        :key="i"
                        @dblclick="query_sub_directories(item.folder_id)"
                      >
                        <v-list-item-icon>
                          <v-icon :color=" item.folder_status === 'M' ? 'green' : '#ffc107'" large>folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.folder_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{
                          $t("movefiles.nofolder")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex v-else>
                  <v-layout justify-center>
                    <br />
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
          </v-card-text>
         </v-tab-item>


         <v-tab-item >
          <v-card-text class="pa-5" >
              <v-layout class="pt-2 pb-4">
                <v-flex d-flex justify-center class="ml-8">
                  <span :style="headerPage">{{
                    filedata.file_type === "folder"
                      ? $t("movefiles.movefolder")
                      : $t("movefiles.movefiles")
                  }}</span>
                </v-flex>
                <v-flex xs1 d-flex justify-end>
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    @click="
                      $emit('closedlmovefile'), cleardata(), $emit('openDrag')
                    "
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-chip
                v-if="createprogress === false"
                :style="headerChipPage"
                :color="$vuetify.theme.dark ? '#989898' : color.theme"
              >
              <v-btn
                  :disabled="enableback"
                  @click="querydirectories_v2(parentdirectory)"
                  style="cursor: pointer; background-color: transparent !important;"
                  icon
                  color="white"
                  class="ml-n1 mr-3"
                >
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'white'"
                    dark
                    size="25"
                    >mdi-arrow-left-circle</v-icon
                  >
                </v-btn>
                <v-list-item-title class="ml-n2">
                  <span
                    :style="headerPageMobile"
                    v-if="resolutionScreen >= 400"
                  >
                    {{ parentname }}</span
                  >
                  <span :style="headerPageMobile" v-else>{{
                    parentname.length >= 30
                      ? parentname.substring(0, 30) + "..."
                      : parentname
                  }}</span>
                </v-list-item-title>
              </v-chip>
          </v-card-text>
          <v-card-text style="overflow-y: scroll; height: 400px;">
              <v-layout row wrap >
                <v-flex v-if="createprogress === false">
                  <v-list class="pb-1" nav dense v-if="listdirectory.length > 0" >
                    <v-list-item-group v-model="item" :color="color.theme">
                      <v-list-item
                        v-for="(item, i) in listdirectory"
                        :key="i"
                        @dblclick="query_sub_directories(item.folder_id)"
                      >
                        <v-list-item-icon>
                          <v-icon :color=" item.folder_status === 'M' ? 'green' : '#ffc107'" large>folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.folder_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list nav dense v-else>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">{{
                          $t("movefiles.nofolder")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex v-else>
                  <v-layout justify-center>
                    <br />
                    <v-progress-circular
                      class="mt-6 mb-6 pt-6 pb-6"
                      :size="50"
                      :dark="color.darkTheme"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                </v-flex>
              </v-layout>
          </v-card-text>
         </v-tab-item>
        </v-tabs>
          <div class="text-center my-4">
            <v-btn
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="40%"
              color="red"
              outlined
              @click="$emit('closedlmovefile'), cleardata(), $emit('openDrag')"
              :disabled="createprogress"
              >{{ $t("movefiles.buttonclose") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="40%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="check_summitmove(), $emit('openDrag')"
              :loading="createprogress"
            >
              {{
                this.item === "" || this.item === undefined ? $t("movefiles.maveat") : $t("movefiles.move")
              }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
    
    <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  
  export default {
    components: {},
    props: ["show", "parentfolder", "filedata"],
    data: function () {
      return {
        enableback: false,
        createprogress: false,
        list_department: [],
        listdirectory: [],
        list_child_file_folder: [],
        list_finish_file_folder: [],
        parentdirectory: "",
        parentname: "",
        parentname_biz: "",
        item: "",
        item_: "",
        moveto: "",
        listbusiness: [],
        countmoveditem: 0,
        tab: 0,
        checktab: 1,
        search: "",
        folderid: "",
        backto_folder: "",
        account_active_inbiz: "",
        full_path: "",
        first_opendialog: true,
        createlodingprogress: false,
        finish_move_success: false
        // wordingbutton: this.$t("movefiles.maveat"),
      };
    },
    computed: {
      ...mapState(["username", "authorize", "account_active", "color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      shower: {
          get() {
          if (this.show === true) {
            console.log("This. file data tab ", this.checktab)
            this.filename = "These file and folder";
            if (this.first_opendialog === true) {
              this.first_opendialog = false;
              if (this.checktab === 1) {
                this.querydirectories_v2(this.parentfolder);
              } else {
                this.querydirectories_v2(this.dataAccountActive["business_info"]["my_folder_id"])
              }
            }
            return this.show;
          }
        },
          set(value) {
            if (!value) {
              this.first_opendialog = true;
              this.$emit("closedbmovefile");
              this.$emit("openDrag");
            }
          },
      },
     
      grant_move_file: {
        get() {
          return (
            this.filedata.file_permission_2 === "04" ||
            this.filedata.file_permission_2 === "05" ||
            this.filedata.file_permission_2 === "06"
          );
        },
      },
      grant_move_files_and_folders: {
          get () {
              return true // Have to fix this line
          }
      },
      // คำนวณเปอร์เซ็นการลบทั้งหมด
      calpercenrestore() {
        let percen = Math.round((this.countmoveditem * 100) / this.filedata.length);
        if (percen === Infinity) {
          return 100;
        } else {
          return percen;
        }
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      // style อักษร header ของ mobile
      headerPage() {
        return (
          "color:" +
          this.color.theme +
          ";" +
          "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
        );
      },
      // style ปุ่ม ของ mobile
      btnAction() {
        return "font-size: 16px; font-weight: lighter;";
      },
      // style อักษรใน chip ของ mobile
      headerPageMobile() {
        return "color:white;" + "font-size: 16px; line-height: 24px;";
      },
      // style chip ของ mobile
      headerChipPage() {
        return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
      },
      // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    },
    watch: {
    tab: function (val) {
      console.log("Check Tab ", val)
      if(val === 0){
        this.querydirectories_v2(this.parentfolder)
      } else {
        this.querydirectories_v2(this.dataAccountActive["business_info"]["my_folder_id"])
      }
    }
  },

    methods: {
    async fn_getlist() {
      this.listbusiness = [];
      let payload = {
        user_id: this.dataUsername,
      };
      console.log("payload searchdirectory", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_biz_for_move_data",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // console.log("response",response.data.result);
            for (let i = 0; i < response.data.result.length; i++) {
              let datalistbusines = {};
              datalistbusines["account_id"] =
                response.data.result[i]["account_id"];
              datalistbusines["business_id"] =
                response.data.result[i]["business_id"];
              datalistbusines["first_name_eng"] =
                response.data.result[i]["first_name_eng"];
              datalistbusines["folder_name"] =
                response.data.result[i]["first_name_th"];
              datalistbusines["folder_id"] =
                response.data.result[i]["my_folder_id"];
              this.listbusiness.push(datalistbusines);
            }
            console.log(" this.listbusiness", this.listbusiness);
            this.parentname_biz = "บริษัท";
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
            console.log("");
          }
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
      // clicker() {
      //   if (this.item === undefined) {
      //     this.wordingbutton = this.$t("movefiles.maveat");
      //   } else {
      //     this.wordingbutton = this.$t("movefiles.move");
      //   }
      // },
      cleardata() {
        this.createprogress = false;
        this.createlodingprogress = false
        this.finish_move_success= false,
        this.tab = 0
        this.checktab = 1
        this.countmoveditem = 0,
        this.listdirectory = [];
        this.list_child_file_folder = [];
        this.list_finish_file_folder = []
        this.parentdirectory = "";
        this.parentname = "";
        this.moveto = "";
        this.item = "";
      },
      close() {},
      check_summitmove() {
        this.summitmovefile();
    },

    async summitmovefile() {
        var payload;
        this.createprogress = true
        await this.timeout(600)
        this.createlodingprogress = true
        let filemoveto = ""
        if(this.item === undefined || this.item === null || this.item === ""){
          filemoveto = this.moveto
        } else {
          filemoveto = this.listdirectory[this.item]["folder_id"]
        } 
        console.log("FILE MOve  ", filemoveto)
        this.$emit('updatemultidatafile', filemoveto)
        this.cleardata()


        //ไม่ได้ใช้งาน
        // for(var i = 0; i < this.filedata.length; i++){
        //   payload = {
        //     data_id: this.filedata[i].file_id,
        //     data_type: this.filedata[i].file_type === "folder" ? "folder" : "file",
        //     account_id: this.dataAccountId,
        //     folder_id_move_to: filemoveto,
        //   };

        //   let resultQueryfile = this.filedata[i].file_type === "folder" ? 
        //   await this.querymovefolder(payload, this.filedata[i]) : await this.querymovefile(payload, this.filedata[i])
        //   console.log("resultQuertFile ", resultQueryfile)
        //   if(resultQueryfile.status === 'ER'){
        //     this.cleardata()
        //     Toast.fire({
        //       icon: "error",
        //       title: this.$t("movefiles.cannotmovefile"),
        //     });
        //     break;
        //   }
        //   this.countmoveditem ++
        //   if(this.filedata.length - 1 === i){
        //     if(this.calpercenrestore === 100){
        //       await this.timeout(1000)
        //       this.finish_move_success = true
        //       await this.timeout(1500)
        //       this.$emit('updatemultidatafile', this.list_finish_file_folder, this.list_child_file_folder)
        //       this.cleardata()
        //       Toast.fire({
        //         icon: "success",
        //         title:
        //         this.$t("movefiles.movefiles") + " " + this.filedata[0].file_name + " " + this.$t("movefiles.success"),
        //       });
        //   }
          
        // }
        // }
    },
 
    
  
    querydirectories(parameter) { // ไม่ได้ใช้แล้ว 
        if(Array.isArray(this.filedata)){
          if(this.grant_move_files_and_folders === true){ // Fix this line permission 
            this.createprogress = true;
            this.enableback = false;
              setTimeout(() => {
                if (this.checktab === 1) {
                    console.log("เข้า1");
                    this.searchchildfolder(parameter);
                } else {
                    console.log("เข้า2");
                    // if(this.parentname_biz === 'root'){
                    //   this.fn_getlist();
                    // }else{
                    this.searchchildfolder_biz(parameter, item);
                    // }
                }
              }, 500);
          }
  
        }else{
          //ตรวจสอบสิทธิ์การย้าย
          if (this.grant_move_file === true) {
              this.createprogress = true;
              this.enableback = false;
              // this.createprogress = false;
              setTimeout(() => {
                if (this.checktab === 1) {
                    console.log("เข้า1");
                    this.searchchildfolder(parameter);
                } else {
                    console.log("เข้า2");
                    // if(this.parentname_biz === 'root'){
                    //   this.fn_getlist();
                    // }else{
                    this.searchchildfolder_biz(parameter, item);
                    // }
                }
              }, 500);
          } else {
              Toast.fire({
              icon: "error",
              title: this.$t("multimovefiles.permission_denied"),
              });
              this.$emit("closedbmovefile");
              this.$emit("openDrag");
          }
        }
        
    },

    querydirectories_v2(parameter, item) {
      this.createprogress = true;
      this.enableback = true;
      setTimeout(() => {
        this.searchchildfolder(parameter);
      }, 500);
    },

    query_sub_directories(parameter) { 
      this.createprogress = true;
      setTimeout(async () => {
        await this.searchchildfolder(parameter);
      }, 500);
      this.enableback = false;
    },
  
    async searchchildfolder(parameter2) {
        this.listdirectory = [];
        this.targetfolder = "";
        this.item = ""
        let payload = {
          user_id: this.dataUsername,
          folder_id: parameter2,
          account_id: this.dataAccountId,
          status_folder: "",
        };
        console.log("payload searchdirectory", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_SELECT_FOLDER + "/api/v2/search_folder_byid",
            //"http://192.168.72.173:8812/api/v2/search_folder_byid",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              // this.targetfolder = Folder ID ที่ต้องการย้าย
              let i;
              for (i = 0; i < response.data.folder.length; i++) {
                if (this.filedata.file_id === response.data.folder[i].folder_id) {
                  continue;
                } else {
                  this.listdirectory.push(response.data.folder[i]);
                }
              }

              //กรณีที่ F
              if (
                response.data.your_folder[0]["folder_status"] === "R" &&
                this.dataAccountActive["type"] === "Business"
              ) {
                //เข้ามาใน Business Drives
                
                if (
                  parameter2 ===
                  this.dataAccountActive["business_info"]["my_folder_id"]
                ) {
                  this.list_department = this.listdirectory.filter((item) => item.folder_status === 'M')
                 console.log("List dep 1", this.list_department)
                  this.parentdirectory = this.dataAccountActive["directory_id"];
                  this.moveto =
                    this.dataAccountActive["business_info"]["my_folder_id"];
                  this.parentname = this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  );
  
                  //อยุ่ใน Department Folder
                } else if (
                  this.dataAccountActive["directory_business"].findIndex(
                    (t) => t.department_folder_id === parameter2
                  ) >= 0 
                  || 
                  this.list_department.map((itemMap) => itemMap.folder_id)
                  .includes(parameter2)

                ) {
                  console.log("List man 2", this.list_department)
                  let index_data = this.list_department.findIndex((t) => t.folder_id === parameter2);
                  this.moveto = this.list_department[index_data]["folder_id"];
                  this.parentdirectory = this.dataAccountActive["business_info"]["my_folder_id"];
                  this.enableback = false;
                  this.parentname = this.list_department[index_data]["folder_name"];
                  
  
                  //อยุ่ใน Root Business
                } else {
                  this.moveto = this.dataAccountActive["directory_id"];
                  this.parentdirectory = this.dataAccountActive["directory_id"];
                  this.enableback = true;
                  this.parentname = this.$t("toolbar.mydrive");
                }
              }  else {
                // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                this.moveto =
                  response.data.your_folder[0]["folder_id"] ||
                  this.dataAccountActive["directory_id"];
                // ! parent_folder_id
                this.parentdirectory =
                  response.data.your_folder[0]["parent_folder_id"] === null
                    ? this.dataAccountActive["type"] === "Business"
                      ? this.dataAccountActive["business_info"]["my_folder_id"]
                      : this.dataAccountActive["directory_id"]
                    : response.data.your_folder[0]["parent_folder_id"];
                this.enableback = false;
                //! ของเดิมใช้ folder_name
                this.parentname = response.data.your_folder[0]["folder_name"];
              }

              //*
  
             
              this.createprogress = false;
            } else {
              console.log("Making this  ")
              this.listdirectory = [];
              this.createprogress = false;
              console.log("");
            }
          })
          .catch((err) => {
            this.listdirectory = [];
            this.createprogress = false;
            console.log(err);
          });
    },

  

   

    },
  };
  </script>
    <style>
  </style>
    